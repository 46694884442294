import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

class RecordsList extends Component {
	constructor(props) {
	  super(props);
	
	  this.delete = this.delete.bind(this);
	  this.state = {
	  	redirect: false
	  }
	}

	delete()
	{
        // var URL = "http://localhost/reactjs/example1_delete.php?id=";
        var URL = "https://reactjs.plusaim.com/reactphpcurd/example1_delete.php?id=";
		axios.get(URL+this.props.obj.id)
		.then(
			this.setState({redirect: true})
		)
		.catch(err => console.log(err))
	}

    render() {
    	const { redirect } = this.state;

    	if(redirect){
    		return <Redirect to="/view" />
    	}

        return (
            <tr>
            	<td>
            		{this.props.obj.id}
            	</td>
            	<td>
            		{this.props.obj.first_name}
            	</td>
            	<td>
            		{this.props.obj.last_name}
            	</td>
            	<td>
            		{this.props.obj.email}
            	</td>
            	
            	<td>
            		<Link to={"/edit/"+this.props.obj.id} className="btn btn-primary">Edit</Link>
            	</td>
            	<td>
            		<button onClick={this.delete} className="btn btn-danger">Delete</button>
            	</td>
            </tr>
        );
    }
}


export default RecordsList;
