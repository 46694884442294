import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router';
export default class Edit extends Component {

	constructor(props) {
	  super(props);
	  this.onChangeFirstName = this.onChangeFirstName.bind(this);
	  this.onChangeLastName = this.onChangeLastName.bind(this);
	  this.onChangeEmail = this.onChangeEmail.bind(this);
	  this.onChangePassword = this.onChangePassword.bind(this);
	  this.onSubmit = this.onSubmit.bind(this);

	  this.state = {
	  	first_name: '',
	  	last_name: '',
	  	email: '',
	  	password:'',
	  	redirect: false
	  }
	}

	componentDidMount(){
		// var URL = 'http://localhost/reactjs/example1_edit.php?id=';
		var URL = "https://reactjs.plusaim.com/reactphpcurd/example1_edit.php?id=";
		axios.get(URL+this.props.match.params.id)
		.then(response=>{
			console.log(response);
			this.setState({ 
				first_name: response.data.first_name,
				last_name: response.data.last_name,
				email: response.data.email,
				password: response.data.password
			});
		})
		.catch(function (error) {
			console.log(error)
		})
	}

	onChangeFirstName(e){
		this.setState({
			first_name: e.target.value
		});
	}

	onChangeLastName(e){
		this.setState({
			last_name: e.target.value
		});
	}

	onChangeEmail(e){
		this.setState({
			email: e.target.value
		});
	}

	onChangePassword(e){
		this.setState({
			password: e.target.value
		});
	}

	onSubmit(e)
	{
		e.preventDefault();

		const obj = {
		  	first_name: this.state.first_name,
		  	last_name: this.state.last_name,
		  	email: this.state.email,
		  	password:this.state.password
		}
		// var URL = 'http://localhost/reactjs/example1_update.php?id=';
		var URL = "https://reactjs.plusaim.com/reactphpcurd/example1_update.php?id=";
		axios.post(URL+this.props.match.params.id, obj)
		.then(res => console.log(res.data),
				this.setState({ redirect:true })
			);

		this.setState({
			first_name: '',
		  	last_name: '',
		  	email: '',
		  	password:''
		});
	}

    render()
	{

		const { redirect } = this.state;

    	if(redirect){
    		return <Redirect to="/view" />
    	}

		return (  
			<div>
				<h3>Edit User</h3>
				<form onSubmit={this.onSubmit}>
				  <div className="form-group">
				    <label for="first_name">First Name:</label>
				    <input type="text" className="form-control" id="first_name" value={this.state.first_name} onChange={this.onChangeFirstName} />
				  </div>
				  <div className="form-group">
				    <label for="last_name">Last Name:</label>
				    <input type="text" className="form-control" id="last_name" value={this.state.last_name} onChange={this.onChangeLastName} />
				  </div>
				  <div className="form-group">
				    <label for="email">Email:</label>
				    <input type="email" className="form-control" id="email" value={this.state.email} onChange={this.onChangeEmail} />
				  </div>
				  <div className="form-group">
				    <label for="password">Password:</label>
				    <input type="password" className="form-control" id="password" value={this.state.password} onChange={this.onChangePassword} />
				  </div>
				  <input type="submit" value="Update User" className="btn btn-primary" id="submit_btn" />
				</form>
			</div>
		)
	}
}