import React, { Component } from 'react';
import axios from 'axios';

import RecordsList from './RecordsList';
class View extends Component {
	constructor(props) {
	  super(props);
	
	  this.state = {students:[]};
	}

	componentDidMount(){
		// var URL = "http://localhost/reactjs/example1_view.php";
		var URL = "https://reactjs.plusaim.com/reactphpcurd/example1_view.php";
		axios.get(URL)
		.then(response=>{
			this.setState({ students: response.data });
		})
		.catch(function (error) {
			console.log(error)
		})
	}

	usersList()
	{
		return this.state.students.map(function(object, i){
			return <RecordsList obj={object} key={i} />;
		});
	}

    render() {
        return (
            <div>
				<h3 align="center">Users List</h3>
				<table className="table table-striped" style={{ marginTop:20 }}>
					<thead>
						<tr>
							<th>Id</th>
							<th>First Name</th>
							<th>Last Name</th>
							<th>Email</th>
							<th colSpan="3">Action</th>
						</tr>
					</thead>
					<tbody>
						{ this.usersList() }
					</tbody>
				</table>
			</div>
        );
    }
}

export default View;


